import {scrollEvent} from './scroll-event';

const _comm= (function() {
	var container= jhc.commElem.container;
	function IndexClass() {};
	$.extend( IndexClass.prototype, {
		config: function() {
			var that= this;
			axios.get('/dist/json/route.json')
			.then(function (res) {
				that.config= res.data;
				console.dir(res.data)
				that.onPopStateEvent();
			})
			.catch(function (error) {
				console.log(error);
			});

			// axios.get('/dist/_sub/route_test.php')
			// .then(function (res) {
			// 	console.dir(res.data);
			// })
			// .catch(function (error) {
			// 	console.log(error);
			// });
			
			const str = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`;
			console.dir(/ipsum/i.exec(str));


			const strMultyLine = `////about/history`;

			console.dir(strMultyLine.match(/[^(\/)][a-z0-9\/]+/gm));

		},
		getMenu: function( routeName ) {
			var	menuName= this.config.router[routeName].menu,
				target= document.querySelectorAll('[data-category="'+ menuName +'"]')[0];
			
			if( target ) {
				var submenu= this.menuCont[0].getElementsByClassName('sub-menu-list')[0];
				if( this.crtSubMenu ) this.crtSubMenu.classList.remove("selected");
				// this.subMenuList.addClass("display-hidden");
				if( this.config.router[routeName].submenu ) 
				{
					var submenuName= this.config.router[routeName].submenu,
						subtarget= this.menuCont[0].querySelectorAll('[data-category="' +submenuName+'"]')[0];
					subtarget.classList.add("selected");
					this.crtSubMenu= subtarget;
				}
			
				if( this.crtMenu ) this.crtMenu.classList.remove("selected");
				target.classList.add("selected");
				this.crtMenu= target;
			}
		},
		checkLang: function( link ) {
			this.lang= document.documentElement.lang;
			this.langPath= this.config.langList[this.lang].path;
			var langPath= '/'+ this.lang
				, linkpage= 
						( this.config.langRange && this.config.langRange.indexOf(this.lang) !== -1 && this.lang !== 'ko' ) ? 
						link.split(langPath)[1] : link;
			return linkpage;
		},
		onPopStateEvent: function() {
			var that= this, linkpage,
				currentState= history.state,
				homeName= 'main',
				routeName= '',
				link = document.location.pathname;

			var linkpage= this.checkLang(link)
				,	linkpage= !linkpage ? '/' : linkpage;

			routeName= (this.config.router[linkpage] && linkpage) || '/404';

			if( routeName === '/' ) {
				routeName= '';
				history.pushState({name: homeName}, '', '');
			}
			this.getMenu(routeName);

			var	relPath= link.split('/'),
			    result= relPath[1].split('/'),
				state= relPath[1].split('/');
			
			window.onpopstate = function(event) {
				var currentState= history.state;
				if(currentState) {
					that.routing(currentState.name, true);
				}
			}
		},
		routing: function ( routeName, hpush, elem ) {
			if ( routeName === '' ) {
				// this.pageRequest(routeName, 'homegate');
				return;
			}
			this.pageRequest(routeName, hpush, elem);
		},
		pageSelectEvt: function(elem) {
			var that= this;
			elem.clickbindtouch(function(e) {
				e.stopPropagation();
				e.preventDefault();
				if( e.ctrlKey || e.shiftKey ) {
					const url= that.config.router[this.dataset.category].route || this.dataset.category
							, langPath= ( that.lang !== 'ko' ) ? `/${that.lang}` : ''
							,	goUrl= `${document.location.origin}${langPath}${url}`;
					console.dir(goUrl)
					window.open(goUrl, '_blank');
					return;
				}
				var routeName= this.dataset.category;
				that.routing(routeName, false, this);
			});
		},
		pageRequest: function( router, htr, self ) {
			var that= this
				, param= {}
				, category= (typeof router === 'string') ? ( this.config.router[router].route || router ) : $(router).data('category')
				// , langCtgPath= this.lang == 'ko' ? category : `/${this.lang}${category}`;
				, langCtgPath= category.match(/[^(\/)][a-z0-9!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]+/gim)
				, langCtgPath= langCtgPath ? langCtgPath[0] : './'
			// console.dir(langCtgPath)
			if( category === undefined ) return;
			
			this.getMenu(category);
			param.formdata= {};
			param.formdata.lang= document.documentElement.lang;
			param.formdata.category= category;
			_jh.each( this.config.router[category], function(i, v) {
				param.formdata[i]= v;
			});
			// param.url= this.routeContLang(this.config.router[category].path);
			param.url= '/dist/index';

			param.complete= function(res) {
				window.scrollTo(0,0);
			}


			if( !htr ) {
				history.pushState({name: category}, langCtgPath, langCtgPath);
			}
				
			if( htr === 'homegate') {
				// this.wrapper.AjaxPrint(this.wrapper.data('param'));
			} else {
				this.menuOff();
				// this.OnModal();

				axios.post(param.url, param.formdata)
		  	.then(function (res) {
		  		that.pageCallback(that.wrapper[0], res, that.config.router[category], langCtgPath);
		  	})
		  	.catch(function (error) {
		    	console.log(error);
		  	});
				
			}
		},
		pageCallback: function( elem, res, params, langCtgPath ) {
			this.langRoute(langCtgPath);
			const titleElem= document.getElementsByTagName('title');
			titleElem[0].innerHTML= this.routeContLang(params.title[this.lang]) + " : " + this.routeContLang(params.name[this.lang]);
			elem.innerHTML= '';
			this.scriptTransform(elem, params.script);
			elem.insertAdjacentHTML('beforeend', res.data);
  		window.scrollTo(0,0);
		},
		langRoute: function(langCtgPath) {
			const target= this.topArea[0].getElementsByClassName('sub-lang-item');
			_jh.each( target, function(i, v) {
				console.log(`/${v.dataset.lang}${langCtgPath}`);
				v.href=`/${v.dataset.lang}${langCtgPath}`;
			});
			console.dir(langCtgPath)
		},
		scriptTransform: function( elem, script ) {
  		_jh.each(script, function (i, v) {
  			const s = document.createElement("script");
  			s.src = v;
  			elem.appendChild(s);
  		});
		},
		routeContLang: function( keypath ) {
			var value=  typeof keypath === 'object' ? keypath[this.lang] : keypath;
			return value;
		},
		init: function(node) {
			this.config(),
			this.node= node,
			this.body= document.body,
			this.document= $(document),
			this.form= $('#frm'),
			this.wrapper= $('#wrapper'),
			this.proxyHead= $('#proxy-head'),
			this.headerArea= $('#head-area'),
			this.topArea= this.headerArea.find('.head-wrapper'),
			this.topHeight= this.topArea.outerHeight(),
			this.menuArea= $('#menu-area'),
			this.menuContArea= $('#menu-cont-area'),
			this.menuContent= $('#menu-content'),
			this.menuCont= $('#menu-cont'),
			this.menuList= this.menuCont.find('.menu-item'),
			this.subMenuList= this.menuList.find('.sub-menu-list'),
			this.pageLink= $('.page-link-comm'),
			this.target, 
			this.targetUrl,
			this.targeObj= {},
			this.menuIcon= $('#menu-icon'),
			this.fixScrBtn= document.getElementsByClassName('fix-scroll-btn'),
			this.sections,
			this.sectionLen,
			this.sectionTop,
			this.partition,
			this.partarr,
			
			this.topScrollBtn= document.getElementById('top-scroll-btn'),
			this.trMask= $('#transition-mask'),
			this.modalArea= $('#modal-area'),
			this.modalScript= this.modalArea.find('.script-area'),
			this.closeArea= this.modalArea.find('.close-area'),
			this.modalWrap= $('#modal-wrap'),
			this.gmapBtn= $('#gmap-btn'),
			this.gmapArea= $('#g-map-area'),
			this.gmapClose= this.gmapArea.find('.close'),
			this.popNotice= document.getElementById('pop-notice'),
			this.popBtn,
			this.GallerySlide= new $.TouchSwipe();
			this.wrapper.data('param', {}),
			this.noticeDebounce= $.noticeFadeout(),
			this.Msbrowser
		},
		run: function() {
			console.log(
				"%c%s%c%s"
				, "color:#fff; background:#e4002b; font-size: 16pt; font-weight: 900; padding: 10px 20px;"
				, 'STX 바이오'
				, "color:#fff; background:#010FE2;; font-size: 16pt;  font-weight: 900; padding: 10px 20px;"
				, 'STX bio Inc.'
			);
			var self= this;
			this.basic();
			this.scrollEvtInstance= new scrollEvent(document);
			// this.scrollEvtInit();
			this.scrollClickEvt($(this.fixScrBtn)),
			this.ClipModal();
			this.ModalCallback();
			this.closeEvt();
			this.ClipMenu();
			this.menuEvt();
			this.pageSelectEvt(this.pageLink);
			// this.menuSelectEvt();
			// this.dmapEvt();
			this.gmapEvt();
			this.cookieMethod();
			if(this.popNotice) {
				this.popup();
			}
		},
		getXml: function( elem, count ) {
			var that= this;

			axios.get('/dist/_sub/news/get_news_data')
			.then(function (res) {
				console.dir(res)
				that.renderNews(res.data.data, elem, count);
	  	})
	  	.catch(function (error) {
	    	console.log(error);
	  	});
		},
		getJson: function( elem, count ) {
			var that= this
				, html= '';
	  	axios.get('/dist/_sub/news/news.json')
	  	.then(function (res) {
	  		var date = new Date();
	    	if( res.data.date === date.yyyymmdd() ) {
	    		that.renderNews(res.data.data, elem, count);
	    	} else {
					that.getXml(elem, count);
	    	}
	  	});
		},
		renderNews: function( res, elem, count ) {
			var html= '';
			for (var i = 0; i <= count; i++) {
				html+=
	  			"<li class='bd-bt-gr mg-h40 cursor-point hover-wrap'>\
						<a href='"+ res[i].url +"' target='_blank' class='flx-itm-r'>\
							<div class='news-item pd-h20 pd-l40'>\
								<h2 class='ft4 ft-b ft-c0-hover tr-3 lh-16 pd-l10'>"+ res[i].title +"</h2>\
							</div>\
						</a>\
					</li>";
			}
  		elem.innerHTML= html;
		},
		popup: function() {
			this.popBtn= this.popNotice.getElementsByClassName('btn')[0];
			var that= this,
				result = this.cookieItc.getCookie('popup'),
				popdefault;

			if (result == 'end') {
				that.popNotice.innerHTML='';
			   return false;
			}
			else {
			   this.popNotice.style.display= 'flex';
			}
			
			this.popBtn.addEventListener('click', function(e) {
				that.popNotice.classList.add('off');
				that.cookieItc.setCookie( "popup", "end" , 1);
			});
		},
		cookieMethod: function() {
			this.cookieItc= {}
			this.cookieItc.deleteCookie= function(cookieName){
		    var expireDate = new Date();
		    expireDate.setDate(expireDate.getDate() - 1);
		    document.cookie = cookieName + "= " + "; expires=" + expireDate.toGMTString();
			}
			 
			this.cookieItc.getCookie= function(cookieName) {
		    cookieName = cookieName + '=';
		    var cookieData = document.cookie;
		    var start = cookieData.indexOf(cookieName);
		    var cookieValue = '';
		    if(start != -1){
		        start += cookieName.length;
		        var end = cookieData.indexOf(';', start);
		        if(end == -1)end = cookieData.length;
		        cookieValue = cookieData.substring(start, end);
		    }
		    return unescape(cookieValue);
			}
			
			this.cookieItc.setCookie= function(cookieName, value, exdays) {
		    var exdate = new Date();
		    exdate.setDate(exdate.getDate() + exdays);
		    var cookieValue = escape(value) + ((exdays==null) ? "" : "; expires=" + exdate.toGMTString());
		    document.cookie = cookieName + "=" + cookieValue;
			}
		},
		basic: function() {
			Date.prototype.yyyymmdd = function()
			{
		    var yyyy = this.getFullYear().toString();
		    var mm = (this.getMonth() + 1).toString();
		    var dd = this.getDate().toString();
		    return yyyy + '-' + (mm[1] ? mm : '0'+mm[0]) + '-' + (dd[1] ? dd : '0'+dd[0]);
			}
		},
		scrollClickEvt: function( elem ) {
			var that= this;
			elem.clickbindtouch(function(e) {
				var target= $('#'+ this.dataset.target);
				that.scrollMoveEvt(target);
			});
		},
		scrollMoveEvt: function(target) {
			target.velocity('scroll', { duration: 750, offset: -this.topHeight });
		},
		ellipsis: function( elem ) {
			var that= this;

			_jh.each(elem, function(i,v) {
				var txt= this.innerHTML,
						len= this.dataset.ellen;
				this.innerHTML= that.txtReplace(txt, len);
			});
		},
		txtReplace: function ( txt, len ) {
			if (txt.length > len) {
			  txt = txt.substr(0, len-2) + '...';
			}
			return txt;
		},
		showModalDetail: function( option ) {
			var that= this
				,	target= option.target
				,	selector= option.selector
				, url= option.url
				,	callback= option.callback
				,	obj= {
						selector: selector,
						callback: function () {
							console.dir(this)
							var self= this,
								listVal= this.querySelectorAll('[data-board-detail]'),
								key= this.dataset.index,
								arr= {};
							$.each(listVal, function(idx, val) {
								arr[this.dataset.boardDetail]= this.innerHTML;
							});
							var paramObj= {
									url: url,
									formdata: {
										key: key,
										data: arr
									},
									complete: function(data) {
										callback && callback(self);
									}
								}
							if( option.params ) paramObj.formdata.params= option.params;
							that.OnModal(false);
							that.modalArea.data('param', paramObj);
							that.modalWrap.AjaxPrint(paramObj);
						}
					};
			target.clickbindtouch(obj);
		},
		showModalCb: function( _self ) {
			var that= this;
			return  function(e) {
				var wrap= $('#templete-wrap')
					, imgArea= wrap[0].getElementsByClassName('image-list')[0];

				if( imgArea !== undefined ){
					var	img= imgArea.getElementsByClassName('image-slide'),
						arrowArea= wrap[0].getElementsByClassName('slide-arrow-area')[0],
						bullet= wrap[0].getElementsByClassName('bullets')[0];
					that.rollingEvt($(img), $(bullet), true, 3, $(arrowArea));
				}
			}
		},
		showModalFront: function( elem ) {
			var that= this;
			_jh.on('click', elem, function(e) {
				that.modalWrap[0].innerHTML= jhc.modalCont[this.dataset.modalCont];
				that.OnModal(false);
			});
		},
		rollingEvt: function( targetLi, bulletUl, bulletPrint, auto, arrowArea) {
			var ImgLen= targetLi.length;
			if( bulletPrint ) {
				for ( var i=0; i < ImgLen; i++ ) {
					bulletUl.append('<li></li>');
				}
			}
			
			var that= this,
				bullet= bulletUl.find('li'),
				selectedImg= $(targetLi[0]).addClass('selected'),
				selectedSubImg= $(bullet[0]).addClass('selected');
			
			if( auto ) {
				_jh.each(targetLi, function(i,v) {
					v.style.animationDuration= v.style.webkitAnimationDuration= auto + 's';
				});
				targetLi.on('animationend webkitAnimationEnd', function(e) {
					if( $(e.target).hasClass('image-slide') && !$(this).hasClass('off')) {
						var index= selectedImg.index() + 1,	
							index= index < ImgLen ? index : 0;
							selectEvt(index);
					}
				});
			}
			
			bullet.clickbindtouch(function(){
				var index= $(this).index();
				selectEvt(index);
			});

			if( arrowArea ) {
				var prevArrow= arrowArea.find('.prev'),
					nextArrow= arrowArea.find('.next');
				
				prevArrow.clickbindtouch(function(){
					var currentIndex= selectedImg.index()-1,
						currentIndex= (currentIndex < 0) ? ImgLen-1 : currentIndex;
					selectEvt(currentIndex);
				});
				nextArrow.clickbindtouch(function(){
					var currentIndex= selectedImg.index()+1,
						currentIndex= (currentIndex > ImgLen-1) ? 0 : currentIndex;
					selectEvt(currentIndex);
				});
			}

			function selectEvt ( index ) {
				var target= targetLi[index],
					subtarget= bullet[index];
				selectedImg.removeClass('selected').addClass('off');
				selectedImg= $(target).removeClass('off').addClass('selected');
				selectedSubImg.removeClass('selected');
				selectedSubImg= $(subtarget).addClass('selected');
			}
		},
		reSetElem: function( sect, obj ) {
			var that= this;
			this.scrollType= 'throttle';
			this.scrollThrottleCb= null;
			this.scrollCurrentCb= null;
			this.scrollImmediatelyCb= null;
			if( obj ) {
				this.scrollType= obj.scrolltype || 'throttle';
				this.scrollThrottleCb= obj.throttle;
				this.scrollCurrentCb= obj.current;
				this.scrollImmediately= obj.immediately;
			}
			this.sections= sect;
			this.sectionLen= this.sections.length;
			this.sectionTop= [];
			/*this.partarr= []
			$.each( this.sections, function(i,v){
				//part top set
				that.partarr[i]= $(this).find('.part');
			});
			this.partition= [];
			this.currentIndex= null;*/
		},
		
		contSelectEvt: function( elem ) {
			var that= this,
				category;
			elem.clickbindtouch(function(e) {
				category= $(this).data('category');
				that.scriptEvt('/_app/'+ category+ '.js');
			});
		},
		scriptEvt: function(url) {
			var s = document.createElement('script');
			s.async = true;
			s.src = url;
			this.modalArea[0].insertBefore(s,this.closeArea[0]);
			this.OnModal(false);
		},
		menuEvt: function() {
			var that= this;
			this.menuArea.on($.IsMobile.click, function(){
				that.menuArea.toggleClass('on');
				that.menuContArea.toggleClass('on');
				// that.body.toggleClass('hidden');
			});
		},
		menuOff: function() {
			this.menuArea.removeClass('on');
			this.menuContArea.removeClass('on');
			// this.body.removeClass('hidden');
		},
		menuSelectEvt: function() {
			var that= this, target;
			this.menuList.clickbindtouch(function(e){
				that.crtMenu.classList.remove("selected");
				that.crtMenu= this;
				this.classList.add("selected");
			});
		},
		ClipMenu: function() {
			var that= this,
				namespace= '.menuClip';
			this.menuContArea.on( 'transitionend webkitTransitionEnd', function(e) {
				if( e.target.id !== this.id || 
					e.originalEvent.propertyName !== 'transform') { return; }
				if($(this).hasClass('on') ) {
					that.document.on($.IsMobile.click + namespace, function (e) {
						if( $(e.target).parents('#menu-cont-area').length > 0 || 
							e.target.id === 'menu-cont-area' ) 
							return;
						that.menuOff();
						return;
					});
				} else {
					that.document.off($.IsMobile.click + namespace);
				}
			});
		},
		OnModal: function(maskOn) {
			if( maskOn === undefined || maskOn === true) {
				this.trMaskEvt();
			} else {
				this.modalArea.addClass('on');
				this.body.classList.add('hidden');
			}
		},
		OffModal: function() {
			var namespace= '.modalClip';
			this.document.off($.IsMobile.click + namespace);
			
			if( this.modalArea.hasClass('on') ) {
				this.modalArea.removeClass('on');
				this.body.classList.remove('hidden');
			} else {
				this.trMaskEvt();
				/*if('node' in this.GallerySlide) {
					this.GallerySlide.destroy();
				}
				this.GallerySlide= {};*/
			}
		},
		ClipModal: function() {
			var that= this,
				namespace= '.modalClip';
			this.modalArea.on( 'transitionend webkitTransitionEnd', function(e) {
				
				if( e.target.id !== this.id || 
					e.originalEvent.propertyName !== 'transform') { return; }
				if( $(this).hasClass('on') ) {
					that.document.on($.IsMobile.click + namespace, function (e) {
						if( $(e.target).parents('#modal-area').length > 0 || 
							e.target.id === 'modal-area' ) 
							return;
						that.OffModal();
						return;
					});
				} else {
					that.document.off($.IsMobile.click + namespace);
				}
			});
		},
		ModalCallback: function() {
			var that= this,
				delayrun;
			this.trMask.on('animationstart webkitAnimationStart', function(e) {
				if($(e.target).hasClass('transition-sheet no1')) {
					delayrun= setTimeout(function(){
						that.wrapper.AjaxPrint(that.wrapper.data('param'));
						window.scrollTo(0,0);
					}, 1000)
				}
			});
			this.trMask.on('animationend webkitAnimationEnd', function(e) {
				if($(e.target).hasClass('transition-sheet no1')) {
					$(this).removeClass('on');
					clearTimeout(delayrun);
				}
			});
			
			this.modalArea.on('transitionend webkitTransitionEnd', function() {
				if( !$(this).hasClass('on') ) {
					that.modalWrap.empty();
				} else {
//					that.modalWrap.AjaxPrint(that.wrapper.data('param'));
				}
			});
		},
		trMaskEvt: function() {
			var that= this;
			this.trMask.addClass('on');
		},
		closeEvt: function() {
			var that= this;
    	this.closeArea.clickbindtouch(function(e) {
    		that.OffModal();
    	});
    },
    dmapEvt: function() {
			var that= this,
				position = daummap.instance.getCenter();
			this.gmapBtn.clickbindtouch(function() {
				daummap.instance.setCenter(daummap.pos);
				that.body.classList.add('hidden');
				that.gmapArea.addClass('show');
			});
			this.gmapClose.clickbindtouch(function() {
				that.body.classList.remove('hidden');
				that.gmapArea.removeClass('show');
			});
		},
		gmapEvt: function() {
			var that= this, myLatLng, centerY, centerX;
				
			this.gmapBtn.clickbindtouch(function(e) {
				centerY= gmapObj.gmap.center.lat(),
				centerX= gmapObj.gmap.center.lng();
				myLatLng = {lat: centerY, lng: centerX};
				that.body.classList.add('hidden');
				that.gmapArea[0].classList.add('show');
				gmapObj.gmap.setCenter(myLatLng);
			});
			this.gmapClose.clickbindtouch(function() {
				that.body.classList.remove('hidden');
				that.gmapArea.removeClass('show');
			});
		}
	});
	
	var $IndexClass= new IndexClass();
	jhc.Class= {}
	jhc.Class.IndexClass= $IndexClass;
	return $IndexClass;
})();
export const comm= _comm;
import config from './module/0-config';

import '@/src/module/1-Plugin';
import PluginFn from '@/src/module/2-Plugin_fn';
import '@/src/module/Velocity';
import './module/3-Gmap';

import {comm} from './module/comm-run';

import '@/src/css/jhc.css';
import '@/src/css/style.css';
import '@/src/css/jhc-m.css';
import '@/src/css/style-m.css';
import '@/src/css/jhc-tab.css';
import '@/src/css/style-main.css';



/*jhc.Class= {
	IndexClass: comm
}*/
// import main from './main';


import jQuery from 'jQuery';
import axios from 'axios';
import _jh from '@/src/module/4-jhc';
import es6Polyfill from '@/src/module/5-es6Polyfill';

function globalInit() {
	window.$= window.jQuery= jQuery;
	global._jh= _jh;
	global.axios= axios;

	global.jhc= {};
	global.jhc.Class= {}
	global.jhc.commElem= {
		container: $('#container'),
		wrapper: $('#wrapper')
	}
	global.jhc.modalCont= {}
	global.jhc.modalCont.policy = 
		'<div id="templete" class="bg-clr-gr">'+
		'	<div id="templete-wrap" class="">'+
		''+
		'		<div class="mx600 mg-ct mg-h80 pd-40 bg-clr-wh m-pd-s20">'+
		'			<h1 class="ft0 ft-b m-ft0">개인정보 제공 동의서</h1>'+
		''+
		'			<p class="mg-t40">'+
		'				<span class="mark-each pd-l20 bg-clr-1-bf pd-l10">개인정보의 수집 및 이용 목적(개인정보보호법 제15조)</span><br><br>'+
		'				1) 당사는 다음과 같은 \'문의사항 접수\' 업무 수행을 위하여 개인정보를 수집 및 이용합니다.<br>'+
		'				- 고객의 문의사항 접수<br>'+
		'				2) 수집된 개인정보는 정해진 목적 이외의 용도로는 이용되지 않으며 수집 목적이 변경될 경우 사전에 알리고 동의를 받을 예정입니다.<br><br><br>'+
		'				<span class="mark-each pd-l20 bg-clr-1-bf pd-l10">수집하는 개인정보의 항목</span><br><br>'+
		'				1. 필수항목: 업체명, 이름, 이메일, 연락처<br>'+
		'				2. 문의사항 양식 작성 후 \'문의보내기\'를 누르면, 작성 내용 및 개인정보가 당사의 메일로 전송됩니다.<br><br><br>'+
		'				<span class="mark-each pd-l20 bg-clr-1-bf pd-l10">개인정보의 보유 및 이용기간</span><br><br>'+
		'				원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br>'+
		'				단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br>'+
		'				- 보존 항목 : 업체명, 이름, 이메일, 전화번호<br>'+
		'				- 보존 근거 : 문의사항 답변을 위한 연락 및 정보전달<br>'+
		'				- 보존 기간 : 3개월<br><br><br>'+
		'				<span class="mark-each pd-l20 bg-clr-1-bf pd-l10">동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익 내용</span><br><br>'+
		'				개인정보 수집에 동의를 거부할 권리가 있으나 최소한의 개인정보 수집동의 거부 시에는 \'문의사항 접수\' 가 제한됩니다.<br>'+
		'			</p>'+
		''+
		'		</div>'+
		'		'+
		'	</div>'+
		'</div>';
}
globalInit();
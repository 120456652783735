
class scrollEventClass {
	constructor ( elem ) {
		this.container= elem;
		this.topArea= document.getElementsByClassName('head-wrapper')[0];
		this.topScrollBtn= document.getElementById('top-scroll-btn');
		this.scrollEvtInit();
		this.windowResizeEvt();
	}
	scrollEvtInit () {
		this.scrollType= 'throttle';
		this.container.addEventListener('scroll', this.handlerset());
	}
	
	reSetElem ( sect, obj ) {
		var that= this;
		this.scrollType= 'throttle';
		this.scrollThrottleCb= null;
		this.scrollCurrentCb= null;
		this.scrollImmediatelyCb= null;
		if( obj ) {
			this.scrollType= obj.scrolltype || 'throttle';
			this.scrollThrottleCb= obj.throttle;
			this.scrollCurrentCb= obj.current;
			this.scrollImmediately= obj.immediately;
		}
		this.sections= sect;
		this.sectionLen= this.sections.length;
		this.sectionTop= [];
	}
	
	offset ( elem ) {
		const rect= elem.getBoundingClientRect()
			, win = elem.ownerDocument.defaultView;
		return {
			top: rect.top + win.pageYOffset,
			left: rect.left + win.pageXOffset,
		}
	}

	reSet () {
		var that= this;
		return function(e) {
			that.topHeight= that.topArea.scrollHeight;
			that.wininnerHeight= window.innerHeight;
			that.sectionTop= [];
			_jh.each( that.sections, function(idx,val){
				that.sectionTop[idx]= that.offset(this).top
			});
		}
	}

	windowResizeEvt () {
		var that= this;
		window.addEventListener('resize', this.reSet());
	}

	handlerset() {
		var that= this,
				breakOut= true,
				oldIndex= null,
				loadingImgOffset= 0,
				windowTop, windowTopMid, windowMiddle, windowMidBtm, windowBottom, timer;

		this.currentIndex= null;


		var scrollEvent= {
			current: function() {
				addOn();
			},
			throttle: function() {
				if( !timer ) {
					timer= setTimeout( function() {
						timer = null;
						addOn();
					}, 200);
				}
			}
		}
		function addOn() {
			that.scrollThrottleCb && that.scrollThrottleCb(windowTop, that.sections);

			if( (document.body.scrollHeight - windowBottom)  < that.wininnerHeight ) {
				that.topScrollBtn.classList.add('show');
			} else {
				that.topScrollBtn.classList.remove('show');
			}
			_jh.each( that.sectionTop, function(i, v) {
				if( (windowMidBtm > v) ||
						(i === (that.sectionLen-1) && windowMiddle > v) ) 
				{
					that.scrollCurrentCb && that.scrollCurrentCb(windowTop, i);
					if( that.currentIndex !== i && !that.sections[i].classList.contains('on') ) {
						oldIndex= that.currentIndex;
						that.currentIndex= i;
						that.sections[i].classList.add('on');
					}
				}

			});
		} 
		
		that.scrollEvent= function (e) {
			windowTop= window.pageYOffset;
			windowTopMid= windowTop + ((windowBottom - windowTop) / 3);
			windowMiddle= windowTop + ((windowBottom - windowTop) / 2);
			windowMidBtm= windowTop + ((windowBottom - windowTop) * .6);
			windowBottom= windowTop + that.wininnerHeight;
			
			that.scrollImmediatelyCb && that.scrollImmediatelyCb(windowTop, that.sections);
			scrollEvent[that.scrollType]();
		}
		return that.scrollEvent;
	
	}
}

export const scrollEvent= scrollEventClass;